import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// DFMEA菜单部分
// 我接收的优化任务
export function receiving(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/newDfmeaOptimizations/receiving`,
    method: 'get',
    params: params.query
  })
}
// 我指派的优化任务
export function appointment(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/newDfmeaOptimizations/appointment`,
    method: 'get',
    params: params.query
  })
}
// 所有的优化任务
export function all(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/newDfmeaOptimizations/all`,
    method: 'get',
    params: params.query
  })
}
// 对所有的优化任务进行统计分析
export function statistics(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/newDfmeaOptimizations/all/statistics`,
    method: 'get',
  })
}
// 获取我收到的未完成的优化任务
export function notComplete(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/newDfmeaOptimizations/receiving/notComplete`,
    method: 'get',
  })
}
// 手动再次提醒优化任务负责人
export function sendAgain(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/newDfmeaOptimizations/${params.riskAndOptimizationId}/notification`,
    method: 'post',
  })
}