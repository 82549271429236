import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
export function getChangeRequest(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequests/received`,
    method: 'get',
    params: params.query
  })
}
export function initiatorRequests(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequests/initiator/requests`,
    method: 'get',
    params: params.query
  })
}
export function getSentRequest(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequests/sent`,
    method: 'get',
    params: params.query
  })
}

export function getAllRequest(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequests/all`,
    method: 'get',
    params: params.query
  })
}

export function sendReminder(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequests/${params.changeRequestId}/remind`,
    method: 'post',
  })
}

export function pingshenDetails(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/changeRequests/${params.changeRequestId}/reviewRecords`,
    method: 'get',
  })
}

export function sortByInitiator(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequests/charts/initiator`,
    method: 'get',
  })
}

export function getRequestNodeAndTimes(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequests/charts/times`,
    method: 'get',
    params: params.query
  })
}

export function getCount(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequests/received/count`,
    method: 'get',
  })
}