<template>
  <div class="options-wrap" :class="{ 'not-allowed': robotTyping }">
    <div
      v-for="(item, index) in optionList"
      :key="index"
      :class="{
        indoc: listIndex,
      }"
      @click="optionSelect(item)"
    >
      <span>{{ item.label }}</span>
      <span>＞</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    optionList: {
      type: Array,
      default() {
        return [];
      },
    },
    listIndex: {
      type: Number,
      default: null,
    },
    robotTyping: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    optionSelect(item) {
      this.$emit("optionSelect", item, this.listIndex);
    },
  },
};
</script>

<style scoped lang="scss">
.not-allowed {
  cursor: not-allowed;
  & > div {
    pointer-events: none;
  }
}
.options-wrap {
  border-radius: 5px;
  background: rgba(235, 244, 255, 1);
  & > div {
    text-align: left;
    font-size: 14px;
    height: 45px;
    line-height: 45px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(220, 233, 247, 1);
    display: flex;
    align-items: center;
    cursor: pointer;
    &:last-of-type {
      border-bottom: none;
    }
    & > span {
      display: inline-block;
      &:first-of-type {
        width: 92%;
      }
      &:last-of-type {
        width: 8%;
        color: rgba(128, 128, 128, 1);
        font-size: 14px;
        font-weight: bold;
        text-align: right;
      }
    }
  }
  .indoc {
    background-color: #fff;
    color: rgba(42, 130, 228, 1);
    padding: 10px 24px;
    line-height: 30px;
    height: fit-content;
    & > span {
      &:last-of-type {
        vertical-align: super;
        color: rgba(42, 130, 228, 1);
      }
    }
  }
}
</style>
